.rich-text-block {
  position: relative;
  width: 100%;
  .container {
    width: var(--app-container);
    margin: 0 auto;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-bottom: 16px;
  }
}