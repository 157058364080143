.image-text-column-block {
  position: relative;
  width: 100%;
  &.two {
    .column {
      width: calc(50% - 22px);
      .image-container {
        height: 363px;
      }
    }
  }
  &.three {
    .column {
      width: calc(33.333% - 22px);
      .image-container {
        height: 234px;
      }
    }
  }
  &.white {
    .container {
      .column {
        background: var(--primary-white);
        .description-container {
          color: var(--primary-black);
          padding: 32px 0;
          .label {
            color: var(--primary-dark-blue-1);
          }
          .btn {
            background: var(--primary-light-blue-2);
            .text {
              color: var(--primary-white);
            }
          }
        }
      }
    }
  }
  &.white-with-blue-border {
    .container {
      .column {
        background: var(--primary-white);
        border: 1px solid var(--primary-dark-blue-1);
        .description-container {
          color: var(--primary-black);
          padding: 32px;
          .label {
            color: var(--primary-dark-blue-1);
          }
          .btn {
            background: var(--primary-light-blue-2);
            .text {
              color: var(--primary-white);
            }
          }
        }
      }
    }
  }
  &.light-blue {
    .container {
      .column {
        background: var(--primary-light-blue-1);
        .description-container {
          color: var(--primary-black);
          padding: 32px;
          .label {
            color: var(--primary-black);
          }
          .btn {
            background: var(--primary-dark-blue-1);
            .text {
              color: var(--primary-white);
            }
          }
        }
      }
    }
  }
  &.dark-blue {
    .container {
      .column {
        background: var(--primary-dark-blue-1);
        .description-container {
          color: var(--primary-white);
          padding: 32px;
          .label {
            color: var(--primary-white);
          }
          .btn {
            background: var(--primary-white);
            .text {
              color: var(--primary-dark-blue-1);
            }
          }
        }
      }
    }
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    width: var(--app-container);
    margin: 0 auto;
    .column {
      .image-container {
        display: flex;
        position: relative;
        width: 100%;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
        }
      }
      .description-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        > * {
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .description {
          > * {
            margin-bottom: 16px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .btn {
          margin-top: 16px;
          @media (hover: hover) {
            &:hover {
              .text {
                color: var(--primary-white);
              }
            }
          }
          &:after {
            background: var(--primary-dark-blue-2);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .image-text-column-block {
    &.two {
      .column {
        width: calc(50% - 16px);
      }
    }
    &.three {
      .column {
        width: calc(50% - 16px);
      }
    }
    .container {
      width: var(--app-container);
    }
  }
}

@media screen and (max-width: 768px) {
  .image-text-column-block {
    &.two {
      .container {
        .column {
          width: calc(100%);
          .image-container {
            height: 223px;
          }
        }
      }
    }
    &.three {
      .container {
        .column {
          width: calc(100%);
          .image-container {
            height: 223px;
          }
        }
      }
    }
    &.white {
      .container {
        .column {
          .description-container {
            padding: 24px 0;
          }
        }
      }
    }
    &.white-with-blue-border {
      .container {
        .column {
          .description-container {
            padding: 24px;
          }
        }
      }
    }
    &.light-blue {
      .container {
        .column {
          .description-container {
            padding: 24px;
          }
        }
      }
    }
    &.dark-blue {
      .container {
        .column {
          .description-container {
            padding: 24px;
          }
        }
      }
    }
    .container {
      gap: 48px;
    }
  }
}