.app-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: var(--primary-white);
  border-bottom: 1px solid #8E959D;
  z-index: 10;
  &.active {
    .hamburger .top,
    .hamburger .bottom {
      transform: scale(0);
    }
    .hamburger .middle-1 {
      transform: rotate(45deg);
    }
    .hamburger .middle-2 {
      transform: rotate(-45deg);
    }
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: var(--app-container);
    margin: 0 auto;
    padding: 16px 0;
  }
  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    max-width: 123px;
    width: 123px;
    height: auto;
    .logo {
      position: absolute;
      width: 100%;
    }
    .logo-tagline {
      height: 100%;
    }
  }
  .navigation-container {
    position: relative;
    display: flex;
    margin-top: -6px;
    .hamburger-container {
      display: none;
      position: relative;
      min-width: 24px;
      width: 24px;
      min-height: 24px;
      height: 24px;
      cursor: pointer;
      -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
      .hamburger {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
    .navigation-items {
      display: flex;
      .navigation-item {
        position: relative;
        margin-left: 32px;
        &:after {
          content: '';
          display: block;
          width: 0;
          height: 4px;
          position: absolute;
          right: 0;
          bottom: 0px;
          z-index: 1;
          transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
          transition-delay: 0ms;
          background: var(--primary-blue);
        }
        &:hover:after {
          width: 100%;
          left: 0;
        }
        &:first-child {
          margin: 0;
        }
        a.black {
          font-size: 18px;
          line-height: 25.2px;
        }
      }
    }
  }
  .hamburger .top,
  .hamburger .bottom {
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.3s ease-in-out;
  }
  .hamburger .middle-1,
  .hamburger .middle-2 {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
    transition: transform 0.3s ease-in-out;
  }
}

@media screen and (max-width: 1200px) {
  .app-header {
    width: 100%;
    .container {
      .navigation-container {
        .navigation-items {
          display: none;
        }
        .hamburger-container {
          display: block;
        }
      }
    }
  }
}