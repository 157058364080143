.contact-form-block {
  display: flex;
  flex-direction: column;
  width: var(--app-container-small);
  margin: 0 auto;
  .intro-text {
    width: 100%;
    margin-bottom: 40px;
  }
  .umbraco-forms-form {
    position: relative;
    overflow: hidden;
    padding: 0;
    .umbraco-forms-navigation {
      padding: 0 !important;
    }
    textarea {
      resize: none;
    }
    form {
      display: flex;
      flex-direction: column;
      width: 100%;
      label.umbraco-forms-label {
        font-family: var(--font-secondary) !important;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.16px;
        font-weight: 600;
        font-style: normal;
        margin-bottom: 2px;
        .umbraco-forms-indicator {
          color: var(--primary-dark-blue-1);
          font-size: 18px;
          line-height: 26px;
        }
      }
      .btn {
        position: relative;
        padding: 8px 32px;
        height: unset;
        border: 0;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        text-decoration: none;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.2px;
        font-weight: 600;
        cursor: pointer;
        &.primary {
          border: 0;
          border-radius: 30px;
          -webkit-border-radius: 30px;
          -webkit-appearance: none;
          background: var(--primary-light-blue-2);
          color: var(--primary-white);
        }
        &:after {
          position: absolute;
          content: '';
          width: 0;
          height: 100%;
          right: 0;
          bottom: 0px;
          transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
          transition-delay: 0ms;
          z-index: 0;
        }
        &.primary:after {
          background: var(--primary-dark-blue-2);
        }
        &:hover:after {
          width: 100%;
          left: 0;
        }
      }
      .umbraco-forms-container {
        display: flex;
        flex-direction: column;
      }
      .umbraco-forms-field {
        width: 100%;
        position: relative;
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
        &.dropdown {
          .umbraco-forms-field-wrapper {
            &:after {
              position: absolute;
              content: '';
              right: 1rem;
              pointer-events: none;
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-top: 6px solid var(--primary-dark-blue-1);
              top: 35px;
              z-index: 20;
            }
          }
        }
      }
      .umbraco-forms-fieldset {
        position: relative;
        padding: 0;
        width: 100%;
        .umbraco-forms-field-wrapper {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          select {
            position: relative;
            width: 100%;
            height: 60px;
            border-radius: 0;
            -webkit-border-radius: 0px;
            font-family: var(--font-secondary) !important;
            padding: 1rem;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.16px;
            appearance: none;
            -webkit-appearance: none;
            &.input-validation-error {
              background: #fbe3e4;
            }
          }
          input {
            max-width: unset !important;
            margin: 0;
            border-radius: 0;
            -webkit-border-radius: 0px;
            width: 100%;
            padding: 1rem;
          }
          textarea {
            border-radius: 0;
            -webkit-border-radius: 0px;
            font-family: var(--font-secondary) !important;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.16px;
          }
          input[type="text"]:focus,
          input[type="tel"]:focus,
          input[type="email"]:focus {
            border-color: var(--primary-blue);
            outline: 0;
          }
          .field-validation-error {
            color: red !important;
            font-family: var(--font-secondary);
            padding-left: 0;
            margin-top: 8px;
            font-size: 16px;
            line-height: 22px;
          }
        }
        .row-fluid {
          .col-md-6 {
            width: calc(50% - 16px);
            float: left;
            margin-right: 32px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        .umbraco-forms-field.enquiry {
          textarea {
            max-width: unset !important;
            width: 100%;
          }
          textarea:focus {
            border-color: var(--primary-blue);
            outline: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .contact-form-block {
    width: var(--app-container);
    .umbraco-forms-form {
      form {
        width: 100%;
        .umbraco-forms-fieldset {
          .row-fluid {
            .col-md-6 {
              width: 100%;
              margin-right: 0;
              margin-bottom: 24px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}