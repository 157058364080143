:root {
  --app-max-container: 1920px;
  --app-container: 1120px;
  --app-container-small: 736px;
}

@media screen and (max-width: 1920px) {
  :root {
    --app-max-container: 100%;
  }
}

@media screen and (max-width: 1200px) {
  :root {
    --app-container: 95%;
  }
}

@media screen and (max-width: 768px) {
  :root {
    --app-container: 90%;
  }
}