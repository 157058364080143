.one-column-text-block {
  position: relative;
  &.small-container {
    .container {
      width: var(--app-container-small);
      margin: 0 auto;
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    width: var(--app-container);
    margin: 0 auto;
    .heading {
      margin-bottom: 16px;
    }
    .body {
      > * {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .one-column-text-block {
    &.small-container {
      .container {
        width: var(--app-container);
      }
    }
  }
}