.page-hero-block {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: 0;
  margin-bottom: 0;
  .container {
    position: relative;
    width: 100%;
    height: 370px;
    .image {
      position: relative;
      width: 100%;
      height: 100%;
      aspect-ratio: 16 / 9;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 1200px) {
  .page-hero-block {
    .container {
      height: 360px;
    }
  }
}

@media screen and (max-width: 992px) {
  .page-hero-block {
    .container {
        height: 270px;
      }
  }
}

@media screen and (max-width: 768px) {
  .page-hero-block {
    .container {
        height: 270px;
    }
  }
}

@media screen and (max-width: 576px) {
  .page-hero-block {
    .container {
        height: 206px;
    }
  }
}

@media screen and (max-width: 480px) {
  .page-hero-block {
    .container {
        height: 180px;
    }
  }
}