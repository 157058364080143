.video-block {
  position: relative;
  width: 100%;
  .container {
    position: relative;
    width: var(--app-container);
    margin: 0 auto;
    .video {
      position: relative;
      width: 100%;
      height: auto;
    }
  }
}