.careers-block {
  position: relative;
  background: var(--primary-light-blue-1);
  padding: 80px 0;
  margin-top: 0;
  margin-bottom: 0;
  .container {
    display: flex;
    width: var(--app-container);
    margin: 0 auto;
    .section {
      flex: 1;
      &.section-heading {
        .heading-wrapper {
          max-width: 90%;
        }
      }
      &.section-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .heading {
          &.has-body {
            margin-bottom: 16px;
          }
        }
        .body {
          p {
            margin-bottom: 16px;
            &:last-child {
              margin: 0;
            }
          }
        }
        .form-container {
          margin-top: 32px;
          .umbraco-forms-form {
            padding: 0;
            input[type="file"] {
              padding: 0;
              border: none;
              height: unset;
              line-height: unset;
              background: none;
              letter-spacing: unset;
            }
            .field-validation-valid {
              display: none;
            }
            ::file-selector-button {
              font-size: initial;
            }
            .umbraco-forms-caption {
              display: none;
            }
            .umbraco-forms-fieldset {
              padding: 0;
              margin: 0;
              .row-fluid {
                .umbraco-forms-container {
                  .umbraco-forms-field.uploadcv {
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;
                    gap: 8px;
                  }
                  .umbraco-forms-tooltip {
                    display: none;
                    // font-size: 16px;
                    // font-family: var(--font-secondary);
                  }
                }
              }
            }
            .umbraco-forms-navigation {
              padding: 0;
            }
          }
        }
        .btn {
          margin-top: 32px;
          padding: 8px 32px;
          height: unset;
          .text {
            font-weight: 600;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .careers-block {
    .container {
      flex-direction: column;
      .section {
        &.section-heading {
          margin-bottom: 32px;
          .heading-wrapper {
            max-width: 100%;
          }
        }
      }
    }
  }
}