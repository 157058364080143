:root {
  --primary-blue: #2A79FF;
  --primary-light-blue-1: #9DB9FF;
  --primary-light-blue-2: #2978ff;
  --primary-dark-blue-1: #004DD1;
  --primary-dark-blue-2: #003796;
  --primary-dark-blue-3: #013151;
  --primary-black: #000000;
  --primary-white: #FFFFFF;
  --generic-grey: #8E959D;
}