.home-hero-block {
  position: relative;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  overflow: hidden;
  .container {
    display: flex;
    position: relative;
    width: var(--app-container);
    margin: 0 auto;
    .section {
      position: relative;
      display: flex;
      align-items: center;
      min-width: 50%;
      &.section-text {
        display: flex;
        flex-grow: 1;
        justify-content: flex-start;
        .section-text-wrapper {
          display: flex;
          flex-direction: column;
          padding: 59.5px 0;
          max-width: 448px;
          width: 448px;
        }
        .heading-container {
          max-width: 90%;
          padding-bottom: 16px;
          .heading {
            color: var(--primary-dark-blue-1);
          }
        }
        .subheading-container {
          padding: 0;
          width: 100%;
        }
      }
      &.section-image {
        display: flex;
        min-width: 704px;
        width: 704px;
        .section-image-wrapper {
          display: flex;
          position: relative;
          width: 100%;
          height: 100%;
          .image {
            position: relative;
            width: 704px;
            height: auto;
            flex-grow: 1;
            object-fit: cover;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .home-hero-block {
    .container {
      width: 90%;
      .section {
        &.section-image {
          min-width: unset;
          .section-image-wrapper {
            .image {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .home-hero-block {
    .container {
      width: var(--app-container);
      margin: 40px auto 0;
      flex-direction: column;
      .section {
        &.section-text {
          .section-text-wrapper {
            margin: unset;
            max-width: 100%;
            width: 100%;
            margin-right: 0;
            margin-bottom: 56px;
            padding: 0;
          }
          .heading-container {
            max-width: 90%;
          }
          .subheading-container {
            max-width: 90%;
          }
        }
        &.section-image {
          min-width: 100%;
          .section-image-wrapper {
            min-width: 100%;
            width: 100%;
            .image {
              min-width: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .home-hero-block {
    .container {
      .section.section-image {
        width: 100%;
        .section-image-wrapper {
          max-width: 100%;
          width: 100%;
          max-height: unset;
          height: unset;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .home-hero-block {
    .container {
      margin-top: 40px;
    }
  }
}