.app-footer {
  position: relative;
  width: 100%;
  background: var(--primary-dark-blue-3);
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    display: flex;
    width: var(--app-container);
    margin: 0 auto;
    padding: 96px 0;
    flex-direction: column;
    .acknowledgement {
      margin-bottom: 56px;
      color: var(--primary-white);
    }
    .links-container {
      display: flex;
      align-items: center;
      width: 100%;
      p {
        margin-right: 16px;
        color: var(--primary-white);
        &.small {
          color: var(--primary-white);
          a.black {
            color: var(--primary-white);
            font-size: 16px;
            line-height: 22px;
            font-weight: 600;
            text-decoration: none;
            text-underline-offset: 3px;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .app-footer {
    .container {
      padding: 64px 0;
      .links-container {
        display: flex;
        width: unset;
        align-items: flex-start;
        flex-direction: column;
        p {
          align-items: flex-start;
          text-align: left;
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}