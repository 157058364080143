* {
  margin: 0;
  padding: 0;
}

html,
body {
  display: flex;
  width: 100%;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.app {
  position: relative;
  width: 100%;
  min-height: 100vh;
  .block-list-container {
    margin-top: 61px;
    @media screen and (max-width: 1200px) {
      margin-top: 51px;
    }
  }
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

input {
  border: 1px solid var(--generic-grey);
  border-radius: 2px;
  height: 60px;
  background: var(--primary-white);
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.16px;
  padding: 0 16px;
}

p.grey,
h4.grey,
h6.grey {
  opacity: 0.8;
}

span.blue {
  color: var(--primary-blue);
}

a {
  color: var(--primary-dark-blue-1);
  text-decoration: underline;
  text-underline-offset: 3px;
  &:hover {
    color: var(--primary-dark-blue-2);
  }
}

button,
a {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}