.app-mobile-navigation {
  position: fixed;
  background: var(--primary-light-blue-1);
  width: 100vw;
  height: 100vh;
  min-height: 0;
  top: 0;
  left: 0;
  z-index: 8;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  &.active {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding-top: 104px;
    padding: 48px 27px;
    .navigation-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      h4 {
        margin-bottom: 32px;
        &:last-child {
          margin: 0;
        }
      }
      a {
        font-size: 26px;
        line-height: 32px;
        letter-spacing: 0.26px;
        font-family: var(--font-primary);
        font-weight: 500;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .app-mobile-navigation {
    &.active {
      position: sticky;
      display: flex;
      visibility: visible;
      opacity: 1;
    }
    .container {
      margin-top: 104px;
    }
  }
}