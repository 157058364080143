@font-face {
  font-family: 'Syne';
  src: url('/static/fonts/Syne-Regular.ttf') format('truetype');
}

:root {
  --font-primary: 'Syne', sans-serif;
  --font-secondary: 'Archivo', sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: var(--font-primary);
  font-weight: 400;
  -moz-font-feature-settings: "ss04" on;
  font-feature-settings: "ss04" on;
}

h6,
p,
ol,
a {
  font-family: var(--font-secondary);
}

h1 {
  font-size: 56px;
  line-height: 67px;
  letter-spacing: 0.8px;
}

h2 {
  font-size: 44px;
  line-height: 53px;
  letter-spacing: 0.56px;
}

h3 {
  font-size: 37px;
  line-height: 44px;
  letter-spacing: 0.46px;
}

h4 {
  font-size: 31px;
  line-height: 37px;
  letter-spacing: 0.36px;
}

h5 {
  font-size: 29px;
  line-height: 35px;
  letter-spacing: 0.26px;
}

h6 {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.2px;
  font-weight: 400;
}

p,
ol {
  font-size: 18px;
  line-height: 25.2px;
  letter-spacing: 0.2px;
  font-weight: 400;
  font-style: normal;
}

ul,
ol,
li,
ul p,
li p,
ol p {
  font-family: var(--font-secondary);
  color: var(--primary-black);
  font-size: 18px;
  line-height: 25.2px;
  font-style: normal;
  letter-spacing: 0.18px;
}

ul,
ol,
li {
  padding-left: 16px;
}

ul,
ol,
li,
ul ul li,
ol ol li,
ul ul li p,
ol ol li p,
ul li p,
ol li p {
  margin-bottom: 12px;
}

ul ul,
ul ul ul,
ol ol,
ol ol ol {
  margin-top: 12px;
  list-style-type: disc;
}

li::marker {
  color: var(--primary-dark-blue-1);
}

p.small {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.16px;
  font-weight: 400;
  font-style: normal;
}

p.small.bold {
  font-weight: 600;
}

a {
  font-size: 18px;
  line-height: 25.2px;
  font-weight: 400;
  letter-spacing: 0.2px;
  font-style: normal;
}

a.black {
  text-decoration: none;
  color: var(--primary-black);
  &:hover {
    color: var(--primary-black);
  }
}

.btn {
  font-family: var(--font-secondary);
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.2px;
  font-weight: 600;
  font-style: normal;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 56px;
    line-height: 64px;
    letter-spacing: 0.56px;
  }

  h2 {
    font-size: 36px;
    line-height: 42px;
    letter-spacing: 0.36px;
  }

  h3 {
    font-size: 34px;
    line-height: 44px;
    letter-spacing: 0.34px;
  }
  
  h4 {
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0.26px;
  }

  h5 {
    font-size: 20px;
    letter-spacing: 0.2px;
    line-height: 28px;
  }

  h6 {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.2px;
  }

  p,
  a,
  ol {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.18px;
  }
}