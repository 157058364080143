.two-column-text-block {
  position: relative;
  padding: 80px 0;
  @media screen and (max-width: 1200px) {
    padding: 40px 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  .container {
    display: flex;
    width: var(--app-container);
    margin: 0 auto;
    .section {
      flex: 1;
      &.section-heading {
        .heading-wrapper {
          max-width: 90%;
        }
      }
      &.section-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .heading {
          &.has-body {
            margin-bottom: 16px;
          }
        }
        .body {
          p {
            margin-bottom: 16px;
            &:last-child {
              margin: 0;
            }
          }
        }
        .disclaimer {
          margin-top: 32px;
        }
        .btn {
          margin-top: 32px;
          .text {
            font-weight: 600;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .two-column-text-block {
    .container {
      flex-direction: column;
      .section {
        &.section-heading {
          margin-bottom: 32px;
          .heading-wrapper {
            max-width: 100%;
          }
        }
      }
    }
  }
}