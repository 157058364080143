.ev-survey-block {
  position: relative;
  width: var(--app-container);
  margin: 40px auto 80px;
  > * {
    font-family: Archivo;
  }
  .header {
    position: relative;
    margin-bottom: 80px;
    .container {
      display: flex;
      width: 100%;
      .section {
        flex: 1;
        &.section-heading {
          .heading-wrapper {
            max-width: 90%;
          }
        }
        &.section-details {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
  .entry-flow {
    position: relative;
    .question-age-gate {
      width: var(--app-container-small);
      margin: 0 auto;
      &.inactive {
        display: none;
      }
      .rich-text {
        margin-bottom: 24px;
        > * {
          margin-bottom: 16px;
          &:last-child {
            margin: 0;
          }
        }
      }
    }
    .age-gate-fail {
      display: none;
      width: var(--app-container-small);
      margin: 0 auto;
      &.active {
        display: block;
      }
      .rich-text {
        > * {
          margin-bottom: 16px;
          &:last-child {
            margin: 0;
          }
        }
      }
    }
    .question-yes-no {
      display: none;
      &.active {
        display: block;
      }
      &.inactive {
        display: none;
      }
      .rich-text {
        margin-bottom: 80px;
        > * {
          margin-bottom: 16px;
          &:last-child {
            margin: 0;
          }
        }
      }
      .question-container {
        width: var(--app-container-small);
        margin: 0 auto;
      }
    }
    .question {
      font-weight: 600;
    }
    .button-container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 16px;
      .btn {
        min-width: 100px;
        width: 100px;
        margin-bottom: 24px;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  .ev-survey {
    position: relative;
    width: 100%;
    .rich-text {
      position: relative;
      &.heading {
        margin-bottom: 80px;
      }
      &.description {
        margin-bottom: 24px;
      }
      > * {
        margin-bottom: 16px;
        &:last-child {
          margin: 0;
        }
      }
    }
    .form-container {
      position: relative;
      width: var(--app-container-small);
      margin: 0 auto;
    }
    &.yes {
      display: none;
      &.active {
        display: block;
      }
    }
    &.no {
      display: none;
      &.active {
        display: block;
      }
    }
    input {
      height: unset;
    }
    .intro-text {
      margin-bottom: 48px;
    }
    .help-block {
      display: block;
      margin-bottom: 24px;
    }
    .umbraco-forms-form fieldset {
      padding: 0;
    }
    .umbraco-forms-container,
    .umbraco-forms-navigation {
      position: relative;
      margin: 0 auto;
      width: 100%;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
    .umbraco-forms-navigation {
      .btn.prev {
        display: none;
      }
      .btn.next {
        background: var(--primary-dark-blue-1);
        color: var(--primary-white);
      }
    }
    .umbraco-forms-fieldset {
      legend {
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0.26px;
        margin-bottom: 24px;
      }
    }
    .umbraco-forms-field {
      &.singlechoice,
      &.multiplechoice {
        margin-bottom: 48px;
      }
      &.longanswer {
        margin-top: -32px;
        margin-bottom: 48px;
      }
      textarea {
        font-family: Archivo;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.18px;
        padding: 14px;
        height: 132px;
        resize: none;
      }
      textarea::placeholder {
        color: --primary-black;
      }
      textarea:focus::placeholder {
        color: transparent;
      }
    }
    .umbraco-forms-label {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: 0.18px;
      margin-bottom: 16px;
    }
    .umbraco-forms-submitmessage {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0.2px;
    }
    .radiobuttonlist,
    .checkboxlist {
      padding: 0 !important;
      float: unset !important;
      .item {
        display: flex;
        align-items: center;
        gap: 36px;
        margin-bottom: 16px;
        label {
          display: inline-block;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: 0.18px;
          &:last-child {
            margin: 0;
          }
        }
        input[type="checkbox"] {
          min-width: 20px !important;
          width: 20px !important;
          min-height: 20px !important;
          height: 20px !important;
          border-radius: 0%;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .ev-survey-block {
    margin: 20px auto 60px !important;
    .header {
      .container {
        flex-direction: column;
        .section {
          &.section-heading {
            margin-bottom: 32px;
            .heading-wrapper {
              max-width: 100%;
            }
          }
        }
      }
    }
    .entry-flow {
      .question-age-gate {
        width: 100%;
      }
      .age-gate-fail {
        width: 100%;
      }
      .question-yes-no {
        .rich-text {
          margin-bottom: 60px;
        }
        .question-container {
          width: 100%;
        }
      }
    }
    .ev-survey {
      .rich-text {
        &.heading {
          margin-bottom: 60px;
        }
      }
      .form-container {
        width: 100%;
      }
    }
  }
}