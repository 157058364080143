.btn {
  position: relative;
  padding: 8px 32px;
  border: 0;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  &.primary {
    background: var(--primary-light-blue-2);
    color: var(--primary-white);
  }
  &.secondary {
    background: transparent;
    color: var(--primary-black);
    border: 2px solid var(--primary-black);
  }
  &.text {
    position: relative;
    z-index: 3;
  }
  &:after {
    position: absolute;
    content: '';
    width: 0;
    height: 100%;
    right: 0;
    bottom: 0px;
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    transition-delay: 0ms;
    z-index: 0;
  }
  &:hover:after {
    width: 100%;
    left: 0;
  }
  &.primary:after {
    background: var(--primary-dark-blue-2);
  }
  &.secondary:after {
    background: var(--primary-light-blue-1);
  }
  .text {
    position: relative;
    pointer-events: none;
    z-index: 3;
  }
}