.image-text-row-block {
  overflow: hidden;
  &.right-align-image {
    .container {
      flex-direction: row-reverse;
    }
  }
  &.white {
    background: var(--primary-white);
    .description-container {
      color: var(--primary-black);
      .label {
        color: var(--primary-dark-blue-1);
      }
      .btn {
        background: var(--primary-light-blue-2);
        .text {
          color: var(--primary-white);
        }
      }
    }
  }
  &.light-blue {
    background: var(--primary-light-blue-1);
    margin-top: 0;
    margin-bottom: 0;
    .container {
      margin: 80px auto;
      .description-container {
        color: var(--primary-black);
        .label {
          color: var(--primary-black);
        }
        .btn {
          background: var(--primary-light-blue-2);
          .text {
            color: var(--primary-white);
          }
        }
      }
    }
  }
  &.dark-blue {
    background: var(--primary-dark-blue-1);
    margin-top: 0;
    margin-bottom: 0;
    .container {
      margin: 80px auto;
      .description-container {
        > *,
        .label,
        ol,
        ul,
        li,
        a,
        li::marker {
          color: var(--primary-white);
        }
        .btn {
          background: var(--primary-white);
          .text {
            color: var(--primary-dark-blue-1);
          }
        }
      }
    }
  }
  &.branded-image {
    .container {
      .image-container {
        .image-wrapper {
          position: relative;
          max-width: 544px;
          width: 544px;
          max-height: 544px;
          height: 544px;
          aspect-ratio: 3 / 2;
          overflow: hidden;
          img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
          }
        }
      }
    }
  }
  .container {
    display: flex;
    align-items: center;
    width: var(--app-container);
    gap: 32px;
    margin: 0 auto;
    .section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-basis: 50%;
    }
    .description-container {
      > * {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .description {
        > * {
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .btn {
        margin-top: 16px;
        @media (hover: hover) {
          &:hover {
            .text {
              color: var(--primary-white);
            }
          }
        }
        &:after {
          background: var(--primary-dark-blue-2);
        }
      }
    }
    .image-container {
      .image-wrapper {
        position: relative;
        width: 100%;
        max-height: 362px;
        height: 362px;
        aspect-ratio: 3 / 2;
        overflow: hidden;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .image-text-row-block {
    &.right-align-image {
      .container {
        flex-direction: column;
      }
    }
    &.branded-image {
      .container {
        width: var(--app-container);
        flex-direction: column;
        align-items: flex-start;
        .section {
          flex: unset;
          width: 100%;
        }
      }
    }
    .container {
      flex-direction: column;
      align-items: flex-start;
      .section {
        flex: unset;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .image-text-row-block.branded-image {
    .container {
      .image-container {
        .image-wrapper {
          position: relative;
          width: 100%;
          height: auto;
          max-height: unset;
          overflow: unset;
          aspect-ratio: unset;
          img {
            position: relative;
            height: auto;
            top: unset;
            left: unset;
            object-fit: unset;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .image-text-row-block {
    &.dark-blue {
      .container {
        margin: 40px auto;
      }
    }
    &.light-blue {
      .container {
        margin: 40px auto;
      }
    }
    .container {
      gap: 16px;
      .image-container {
        .image-wrapper {
          .image-inner {
            max-width: unset;
            max-height: unset;
            width: 100%;
            height: 280px;
          }
        }
      }
    }
  }
}